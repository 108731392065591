<template>
    <common-form
            ref="form"
            url="web/machine/product"
            :edit="edit"
            :items="items"
            :before-request="beforeRequest"
            @success="$emit('success')">



        <template slot="range">

            <a-table :columns="paraColumns" :data-source="paramsArray" bordered size="middle" :pagination="false">

                <template slot="price" slot-scope="text, record, index">
                    <a-input v-model="record.price"></a-input>
                </template>

            </a-table>

        </template>

    </common-form>
</template>
<script>

    export default {
        data() {
            return {
                edit: false,
                treeData: [],
                paraColumns: [
                    {
                        title: '类型',
                        dataIndex: 'name',
                        width:150
                    },
                    {
                        title: '价格',
                        dataIndex: 'price',
                        scopedSlots: {customRender: 'price'},
                    },
                ],
                paramsArray:[
                    { name:"采购价", price:"" },
                    { name:"省级代理价", price:"" },
                    { name:"市级代理价", price:"" },
                    { name:"网点代理价", price:"" },
                    { name:"官方指导价", price:"" },
                ],
                brandOptions:[],
                classifyOptions:[]
            }
        },
        computed: {
            items() {
                return [
                    {
                        type: "image",
                        name: "产品图片",
                        key: "logo",
                    },
                    {
                        type: "select",
                        name: "品牌",
                        key: "brandId",
                        required: true,
                        options:this.brandOptions
                    },
                    {
                        type: "cascader",
                        name:"分类",
                        key:"classifyId",
                        required: true,
                        options:this.classifyOptions
                    },
                    {
                        type: "input",
                        name: "商品编码",
                        key: "sn",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "名称",
                        key: "name",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "型号",
                        key: "model",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "产地",
                        key: "origin",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "计量单位",
                        key: "unit",
                    },
                    {
                        type: "input",
                        name: "件号",
                        key: "partNo",
                    },
                    {
                        type: "input",
                        name: "包装尺寸",
                        key: "size",
                    },
                    {
                        type: "input",
                        name: "重量",
                        key: "weight",
                    },
                    {
                        type: "slot",
                        name: "价格",
                        slot: "range"
                    },
                    {
                        type: "input",
                        name: "供应商",
                        key: "supplier",
                    },
                    {
                        type: "input",
                        name: "商城商品ID",
                        key: "mallGoodsId",
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                let data = {
                    ...model
                }
                if(this.edit){
                    data.classifyId = [model.classifyId1,model.classifyId2]
                    this.paramsArray[0].price = model.pricePurchase/100
                    this.paramsArray[1].price = model.priceProvince/100
                    this.paramsArray[2].price = model.priceCity/100
                    this.paramsArray[3].price = model.priceNetwork/100
                    this.paramsArray[4].price = model.priceOfficial/100
                }else{
                    this.paramsArray[0].price = ""
                    this.paramsArray[1].price = ""
                    this.paramsArray[2].price = ""
                    this.paramsArray[3].price = ""
                    this.paramsArray[4].price = ""
                }
                this.$refs.form.show(data)

                this.$get('web/machine/brand/all').then(suc=>{
                    suc.data.forEach(item=>{
                        item.value = item.id
                    })
                    this.brandOptions = suc.data
                    this.$refs.form.show(data)
                })

                this.$get('web/machine/classify/all').then(suc=>{
                    suc.data.forEach(parent=>{
                        parent.value = parent.id
                        parent.label = parent.name

                        parent.children.forEach(child=>{
                            child.value = child.id
                            child.label = child.name
                        })
                    })
                    this.classifyOptions = suc.data
                    this.$refs.form.show(data)
                })
            },

            beforeRequest(model){
                return {
                    ...model,
                    classifyId1:model.classifyId[0],
                    classifyId2:model.classifyId[1],
                    pricePurchase:parseInt(this.paramsArray[0].price*100),
                    priceProvince:parseInt(this.paramsArray[1].price*100),
                    priceCity:parseInt(this.paramsArray[2].price*100),
                    priceNetwork:parseInt(this.paramsArray[3].price*100),
                    priceOfficial:parseInt(this.paramsArray[4].price*100),
                }
            }

        }
    }
</script>
